<template>
    <div>
        <el-card class="management-report-card-box">
            <el-form>
                <div class="flex flex-row justify-between items-center mb-2">
                    <h2 class="text-base font-semibold">{{ title }}</h2>
                    <div class="flex items-center">
                        <div v-if="yearlyOverviewData" class="space-x-2">
                            <el-tooltip v-show="!yearlyOverviewData.isRowsOriginal" placement="top">
                                <el-tag type="danger">Det har skett ändringar i bokföringen</el-tag>
                                <div slot="content" class="tooltip-content">
                                    <p>Korrekta summor har inte beräknats, uppdatera för att hämta nya summor.</p>
                                </div>
                            </el-tooltip>
                            <el-tooltip v-show="isMissingSruCodeAccounts" placement="top">
                                <el-tag type="danger">SRU kod saknas, korrekta summor har inte beräknats</el-tag>
                                <div slot="content" class="tooltip-content">
                                    <p>Vänligen lägg till SRU-koder för följande konton för att säkerställa korrekta beräkningar i flerårsöversikten:</p>
                                    <div v-for="(row, index) in yearlyOverviewData.missingSruCodeAccounts" :key="index">
                                        <p>{{ row.account }} {{ row.name }}</p>
                                    </div>
                                    <br />
                                    <p>Uppdatera för att hämta nya summor.</p>
                                </div>
                            </el-tooltip>
                        </div>
                        <RefreshButton
                            :card-title="title"
                            :annual-report-id="annualReportId"
                            :refresh-data="refreshData"
                            loading-key="loading-multiYearOverView"
                            :success-message="`Hämtat ny data till flerårsöversikt`"
                            :disable-button="showEditButton"
                        />
                        <InfoButton :content="infoContent" />
                        <el-switch v-model="localToggleValue" active-color="#48c78e" inactive-color="#D1D5DB" @change="onToggleChange" />
                    </div>
                </div>
                <div style="width: fit-content;" v-if="toggleValue && yearlyOverviewData != null" v-loading="$waiting.is('loading-multiYearOverView')">
                    <div style="margin-left: 0px;" class="flex">
                        <div v-for="(year, index) in yearlyOverviewData.years" :key="index">
                            <el-input :style="{ width: getWidth(year), padding: '10.5px' }" :value="getCombinedValue(index)" type="text" disabled />
                        </div>
                    </div>
                    <el-table :data="yearlyOverviewData.rows" size="mini" :show-header="false">
                        <el-table-column prop="description" width="250" editable>
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.description" disabled />
                            </template>
                        </el-table-column>
                        <el-table-column v-for="(value, index) in yearlyOverviewData.rows[0].amounts" :key="index" prop="amounts" editable>
                            <template slot-scope="scope">
                                <el-input v-model.number="scope.row.amounts[index]" type="number" :value="scope.row.amounts[index] || 0" :disabled="inputDisabled" />
                            </template>
                        </el-table-column>
                    </el-table>
                    <div v-show="localShowComment">
                        <h2 class="text-base font-semibold mb-2 mt-4">Kommentar:</h2>
                        <el-input type="textarea" :disabled="inputDisabled" v-model="localInputText" rows="2" class="break-normal custom-textarea" />
                    </div>
                </div>
            </el-form>
            <CardEditButtons
                :toggle-value="toggleValue"
                :show-edit-button="showEditButton"
                :toggle-edit="toggleEdit"
                :close-modal="closeModal"
                :save-data="saveData"
                :show-comment-button="true"
                :show-comment="localShowComment"
                @toggle-comment="handleToggleComment"
            />
        </el-card>
    </div>
</template>
<script>
import { Switch } from "element-ui";
import Api from "../annualReport.api";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        inputText: {
            type: String,
            default: "",
        },
        yearlyOverviewData: {
            type: Object,
            default: () => {},
        },
        toggleValue: {
            type: Boolean,
            default: true,
        },
        showComment: {
            type: Boolean,
        },
        annualReportId: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            updatedYearlyOverviewData: [],
            inputDisabled: true,
            showEditButton: true,
            localToggleValue: this.toggleValue,
            localInputText: this.inputText,
            infoContent: `För att uppfylla kraven på en rättvisande översikt enligt 6 kap. 1 § första stycket ÅRL ska företaget för räkenskapsåret och de tre föregående åren lämna uppgift om <br />a) nettoomsättning, <br />b) resultat efter finansiella poster, och <br />c) soliditet (justerat eget kapital i procent av balansomslutning). <br /><br />Varierar nettoomsättningen mer än 30 procent mellan åren ska företaget kommentera detta (se egen rubrik i trädet till vänster). Företaget får ange ytterligare nyckeltal. <br /><br /> K2 (BFNAR 2016:10) kapitel 4 punkt 9 och kapitel 5 punkt 5.`,
            initialInputText: "",
            isInitialInputTextSet: false,
            initialYearlyOverviewData: [],
            isInitialYearlyOverviewDataSet: false,
            newRows: [],
            localShowComment: this.showComment,
        };
    },
    components: {
        "el-switch": Switch,
        CardEditButtons: () => import("./components/CardEditButtons.vue"),
        InfoButton: () => import("./components/InfoButton.vue"),
        RefreshButton: () => import("./components/RefreshData.vue"),
    },
    watch: {
        inputText(newVal) {
            this.localInputText = newVal;
            if (!this.isInitialInputTextSet) {
                this.initialInputText = newVal;
                this.isInitialInputTextSet = true;
            }
        },
        localInputText(newVal) {
            this.$emit("update:text", newVal);
        },
        toggleValue(newVal) {
            this.localToggleValue = newVal;
        },
    },
    methods: {
        async refreshData() {
            const refreshMultiYearOverview = await Api.getManagementReportBooking(this.annualReportId, "multiYearOverview");
            this.newRows = refreshMultiYearOverview;
            this.$emit("update:row", this.newRows);
        },
        handleToggleComment(newShowComment) {
            this.localShowComment = newShowComment;
        },
        toggleEdit() {
            this.inputDisabled = !this.inputDisabled;
            this.showEditButton = !this.showEditButton;
        },
        onToggleChange(newVal) {
            const eventData = {
                source: 5,
                value: newVal,
            };
            this.$emit("toggle-change", eventData);
        },
        emptyValues(index) {
            return this.updatedYearlyOverviewData.rows[index].amounts.some(amount => amount === null || amount === "");
        },
        async saveData() {
            this.updatedYearlyOverviewData = this.yearlyOverviewData;
            this.updatedYearlyOverviewData.showYearlyOverviewComment = this.localShowComment;
            if (this.emptyValues(0) || this.emptyValues(1) || this.emptyValues(2)) {
                this.$notify.error({ title: "Fyll in alla fält i flerårsöversikten" });
            } else {
                this.$emit("update-yearly-overview-data", this.updatedYearlyOverviewData);
                this.$emit("create");
                this.$notify.success({ title: 'Sparat "Flerårsöversikt"' });
                this.toggleEdit();
            }
        },
        closeModal() {
            const load = "loading-multiYearOverView";
            this.$emit("close", { load: load, inputTextNewValue: this.inputTextNewValue });
            this.toggleEdit();
        },
        getWidth(year) {
            return year === "Flerårsöversikt (Tkr)" ? "250px" : "auto";
        },
        getCombinedValue(index) {
            return `${this.yearlyOverviewData.years[index] || ""} ${this.yearlyOverviewData.months[index] || ""}`;
        },
    },
    computed: {
        inputTextNewValue() {
            return this.initialInputText != this.localInputText;
        },
        isMissingSruCodeAccounts() {
            return this.yearlyOverviewData.missingSruCodeAccounts.length > 0;
        },
    },
};
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
</style>
